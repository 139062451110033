import hoistNonReactStatic from 'hoist-non-react-statics';
import * as React from 'react';

import { Consumer } from './context';
import { ActionSheetProps } from './types';

export default function connectActionSheet<OwnProps = any>(
  WrappedComponent: React.ComponentType<OwnProps & ActionSheetProps>
): React.ForwardRefExoticComponent<React.PropsWithoutRef<OwnProps> & React.RefAttributes<unknown>> {
  const ConnectedActionSheet = React.forwardRef((props: OwnProps, ref) => {
    return (
      <Consumer>
        {({ showActionSheetWithOptions }) => {
          const wrappedComponentProps: any = { ...props, showActionSheetWithOptions };

          if (ref) wrappedComponentProps.ref = ref;

          return <WrappedComponent {...wrappedComponentProps} />;
        }}
      </Consumer>
    );
  });

  return hoistNonReactStatic(ConnectedActionSheet, WrappedComponent);
}
