"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("./helpers");
const useWindowSizes_1 = require("./useWindowSizes");
// Will recursively combine all the styles as StyleSheet.compose only takes two arguments
const composeMultipleStyles = (styles, classNames) => {
    // Base cases to return the style if there are not two for a composition
    if (classNames.length === 1)
        return styles[classNames[0]];
    // Recursively combine the rest of the styles
    const nextIteration = composeMultipleStyles(styles, classNames.slice(1));
    return [styles[classNames[0]], nextIteration];
};
const getCustomStyles = (styles, size, className) => {
    // Will match any string that contains the size (in any order) followed by the style name
    const regex = new RegExp(`(^|\\+)${size}[a-zA-Z0-9+-]*_${className}$`);
    // Will get a list of all style keys that are matching either with a sie prefix or the base class
    const styleKeys = Object.keys(styles).filter((style) => style.match(regex) || style === className);
    if (styleKeys.length === 0)
        return null;
    // Will merge the styles given the key constraints defined above
    return composeMultipleStyles(styles, styleKeys);
};
function useResponsiveStyle(styles) {
    return () => {
        const layout = (0, useWindowSizes_1.default)(helpers_1.deviceSize);
        const size = (0, helpers_1.deviceSize)(layout.width);
        return {
            styles: (style) => getCustomStyles(styles, size, style),
            deviceSize: size,
        };
    };
}
exports.default = useResponsiveStyle;
